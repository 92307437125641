import { markRaw, defineAsyncComponent } from 'vue'
import {
  useModal,
  useModalSlot,
  VueFinalModal,
} from 'vue-final-modal'

import ModalWrapper from '@/components/modal/modal-container.vue'

export function useModalOpener(
  componentProps,
  modalProps,
  finish
) {
  const onFinish = (arg) =>
    new Promise((resolve, reject) => {
      if (arg !== undefined) {
        resolve(arg)
        return
      }
      reject()
    })
      .then((result) => {
        finish(result)
        close()
      })
      .catch((err) => {
        console.warn('use-modal-on-finish', err)
      })

  const {
    options,
    open,
    close,
    patchOptions,
    destroy,
  } = useModal({
    component: VueFinalModal,
    attrs: {
      onClose() {
        close()
      },
      onFinish,
      clickToClose: true,
      ...modalProps,
    },
    slots: {
      default: useModalSlot({
        component: markRaw(ModalWrapper) as unknown as any,
        attrs: {
          onClose() {
            close()
          },
          onFinish,
          clickToClose: true,
          ...componentProps,
        },
      }),
    },
  })

  return { options, open, close, patchOptions, destroy }
}
