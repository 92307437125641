import {
  createSender,
  createReceiver,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
} from '@/resource/api'

const MODEL = 'domains'

const DOMAINS_LINK = prefixAPI('/{?buyer}', MODEL, API_EXTERNAL_URL)
const DOMAINS_CREATE_LINK = prefixAPI('/bulk-create', MODEL, API_EXTERNAL_URL)
const DOMAINS_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL, API_EXTERNAL_URL)

export const getDomainsList = createReceiver(DOMAINS_LINK)
export const createDomain = createSender(DOMAINS_CREATE_LINK)
export const updateDomain = createUpdater(DOMAINS_UPDATE_LINK)

