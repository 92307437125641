import { route } from '@/router/helpers'

import BasePage from '@/components/pages/base-view.vue'
import AuthPage from '@/components/pages/auth.vue'
import DomainsPage from '@/components/pages/domains.vue'
import BuyersPage from '@/components/pages/buyers.vue'

export const INITIAL_VIEW_URL = '/'

const p = l => `${INITIAL_VIEW_URL}${l}`

const DOMAINS_PAGE_URL = p('domains')
const AUTH_PAGE_URL = p('login')
const BUYERS_PAGE_URL = p('buyers')

const BASE_PAGE_NAME = 'pages:base'
const DOMAINS_PAGE_NAME = 'pages:domain'
const BUYERS_PAGE_NAME = 'pages:buyers'
const AUTH_PAGE_NAME = 'pages:auth'



function createRoutes(options: object): any[] {
  return [
    route(INITIAL_VIEW_URL, BasePage, BASE_PAGE_NAME, {
      children: [
        route(AUTH_PAGE_URL, AuthPage, AUTH_PAGE_NAME),
        route(DOMAINS_PAGE_URL, DomainsPage, DOMAINS_PAGE_NAME),
        route(BUYERS_PAGE_URL, BuyersPage, BUYERS_PAGE_NAME),
      ],
      redirect: {
        name: DOMAINS_PAGE_NAME
      },
    })
  ]
}

export {
  DOMAINS_PAGE_NAME,
  BUYERS_PAGE_NAME,
  AUTH_PAGE_NAME,
  AUTH_PAGE_URL,
  createRoutes,
} 
