import {
  createReceiver,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
} from '@/resource/api'

const MODEL = 'tds'

const TDS_LIST_LINK = prefixAPI('/list/', MODEL, API_EXTERNAL_URL)

export const tdsListReceiver = createReceiver(TDS_LIST_LINK)

