import { App } from 'vue'

import AuthForm from './auth-form.vue'
import AddDomainForm from './add-domain.vue'
import AddBuyerForm from './add-buyer.vue'
import EditBuyerForm from './edit-buyer.vue'
import EditDomainForm from './edit-domain.vue'



const register = (app: App<Element>): void => {
  app
  .component('AuthForm', AuthForm)
  .component('AddDomainForm', AddDomainForm)
  .component('AddBuyerForm', AddBuyerForm)
  .component('EditBuyerForm', EditBuyerForm)
  .component('EditDomainForm', EditDomainForm)
}

export default {
  register,
}