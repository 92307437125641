import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "block-container" }
const _hoisted_2 = { class: "domain-container" }
const _hoisted_3 = { class: "g-row g-row--appearance_spaced g-row--justify_between g-row--align_center" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--narrow-xs" }
const _hoisted_5 = { class: "ds-caption ds-caption--bold ds-caption--size_hd" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--narrow-xs" }
const _hoisted_7 = { class: "button__icon" }
const _hoisted_8 = { class: "button__text" }
const _hoisted_9 = { class: "g-row g-row--appearance_spaced g-row--justify_between g-row--align_center" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--4-xs" }
const _hoisted_11 = { class: "relative" }
const _hoisted_12 = { class: "ui-table" }
const _hoisted_13 = { class: "ui-table__head" }
const _hoisted_14 = { class: "table-header" }
const _hoisted_15 = {
  key: 0,
  class: "ui-table__body"
}
const _hoisted_16 = { class: "remove-border" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "button__text" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { colspan: "3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_plus = _resolveComponent("i-plus")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_ui_loader = _resolveComponent("ui-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('Домены')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "button button--appereance_border",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.addDomainOpenModal()), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_i_plus)
            ]),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('Добавить домен')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_d_control_input, {
            component: "d-control-multiselect",
            staticLabel: _ctx.$t('Баер'),
            options: $setup.buyersList,
            placeholder: _ctx.$t('Баер'),
            modelValue: $setup.currentBuyer,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => (($setup.currentBuyer) = $event)),
              _cache[2] || (_cache[2] = e => $setup.onChangeBuyers(e))
            ],
            value: $setup.currentBuyer,
            name: "buyer",
            "track-by": "id",
            "label-by": "name",
            "allow-empty": false,
            rules: "required",
            onClearMultiselect: $setup.onClearBuyers
          }, null, 8, ["staticLabel", "options", "placeholder", "modelValue", "value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        ($setup.isLoad)
          ? (_openBlock(), _createBlock(_component_ui_loader, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("table", _hoisted_12, [
          _createElementVNode("thead", _hoisted_13, [
            _createElementVNode("tr", _hoisted_14, [
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('Домен')), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('Баер')), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('TDS')), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('Статус')), 1)
              ])
            ])
          ]),
          ($setup.domainsList.length)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.domainsList, (item) => {
                  return (_openBlock(), _createElementBlock("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(item.domain), 1),
                    _createElementVNode("td", null, _toDisplayString($setup.getBuyersName(item.buyer)), 1),
                    _createElementVNode("td", null, _toDisplayString($setup.getTdsName(item.tds)), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(item.status == '0-act' ? 'color_green' : 'color_red')
                      }, _toDisplayString(item.status == '0-act' ? _ctx.$t('Активен') : _ctx.$t('Скомпрометирован')), 3)
                    ]),
                    _createElementVNode("td", _hoisted_16, [
                      _createElementVNode("div", {
                        class: "button button--appereance_border button--action",
                        onClick: ($event: any) => ($setup.openEditDomainModal(item))
                      }, [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('Редактировать')), 1)
                      ], 8, _hoisted_17)
                    ])
                  ]))
                }), 256))
              ]))
            : (_openBlock(), _createElementBlock("tr", _hoisted_19, [
                _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.$t('No data to display')), 1)
              ]))
        ])
      ])
    ])
  ]))
}