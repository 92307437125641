import {
  createSender,
  createReceiver,
  createRemover,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
} from '@/resource/api'

const MODEL = 'buyers'

const BUYERS_CREATE_LINK = prefixAPI('/create/', MODEL, API_EXTERNAL_URL)
const BUYERS_DELETE_LINK = prefixAPI('/delete/{id}/', MODEL, API_EXTERNAL_URL)
const BUYERS_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL, API_EXTERNAL_URL)
const BUYERS_LIST_LINK = prefixAPI('/list/', MODEL, API_EXTERNAL_URL)

export const buyersCreateReceiver = createSender(BUYERS_CREATE_LINK)
export const buyersDeleteReceiver = createRemover(BUYERS_DELETE_LINK)
export const buyersUpdateReceiver = createUpdater(BUYERS_UPDATE_LINK)
export const buyersListReceiver = createReceiver(BUYERS_LIST_LINK)

